import React, { FC } from 'react';

import './Nav.scss';

import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faArrowRightFromBracket, faEnvelope, faMessageLines } from '@fortawesome/pro-solid-svg-icons';
import { faCircleChevronLeft, faFiles } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CompactLogo } from '../Logo/compactLogo.svg';
import Logo from '../Logo/Logo';
import useUserNavList from '../../hooks/useUserNavList';
import useUserType from '../../hooks/useUserType';
import ClientSupportModal from '../Support/ClientSupportModal';
import NavItem from './NavItem';
import usePersistState from '../../hooks/usePersistState';
import useAuth from '../../hooks/useAuth';
import eventHelper from '../../helpers/eventHelper';
import events from '../../constants/events';
import UserType from '../../models/Account/UserType';
import NavigationType from '../../models/AppGallery/Module/NavigationType';
import MessagesNavItem from './MessagesNavItem';

const Nav: FC = () => {
  const navigate = useNavigate();
  const {
    api: { logout },
  } = useAuth();
  const [navState, setNavState] = usePersistState<'normal' | 'compact'>('nav_state', 'normal');
  const navList = useUserNavList();
  const userType = useUserType();

  const [isShowSupportModal, setIsShowSupportModal] = React.useState(false);

  const logoutHandler = async () => {
    await logout();

    window.dispatchEvent(new Event(events.logouted));
    navigate('/logout');
  };

  const onKeyDownLogoutHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (eventHelper.isSubmitKeyPressed(e)) {
      logoutHandler();
    }
  };

  const onClickLogoutHandler = async () => logoutHandler();

  const onContactHandler = () => {
    setIsShowSupportModal(true);
  };
  const closeContactModalHandler = () => {
    setIsShowSupportModal(false);
  };
  const onKeyDownContactHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (eventHelper.isSubmitKeyPressed(e)) {
      onContactHandler();
    }
  };
  const onClickCloseMenuHandler = () => {
    setNavState('compact');
  };

  const onKeyDownCloseMenuHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (eventHelper.isSubmitKeyPressed(e)) {
      setNavState('compact');
    }
  };

  const onClickOpenMenuHandler = () => {
    setNavState('normal');
  };

  const onKeyDownOpenMenuHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (eventHelper.isSubmitKeyPressed(e)) {
      setNavState('normal');
    }
  };

  return (
    <div className={`nav-component d-flex flex-column text-uppercase ${navState}`} data-test-id="navigation">
      <div className="pb-6 pt-4 px-4 align-items-center d-flex justify-content-between position-relative">
        <div
          role="button"
          className="compact-logo position-absolute"
          onClick={onClickOpenMenuHandler}
          onKeyDown={onKeyDownOpenMenuHandler}
          tabIndex={0}
          data-test-id="navigation-open-button"
        >
          <CompactLogo />
        </div>
        <div className="logo">
          <Logo />
        </div>
        <div
          role="button"
          className="p-1 close-menu-btn"
          onClick={onClickCloseMenuHandler}
          onKeyDown={onKeyDownCloseMenuHandler}
          tabIndex={0}
          data-test-id="navigation-close-button"
        >
          <FontAwesomeIcon icon={faCircleChevronLeft} />
        </div>
      </div>
      <div className="d-flex flex-column pt-7">
        <div className="nav-title d-flex size-caption justify-content-between text-uppercase ps-4 text-secondary pb-4 font-bold">
          <FormattedMessage id="nav.title" />
        </div>
        {navList.map((nav) => (
          <NavItem
            key={nav.item.name}
            path={nav.item.type === NavigationType.Frame ? `/external/${nav.item.name}` : nav.item.path}
            icon={nav.icon}
            labelKey={nav.item.name}
            dataTestId={`navigation-item-${nav.item.name}`}
          />
        ))}
      </div>

      <div className="d-flex flex-column mt-auto pb-4">
        {userType !== UserType.Admin && (
          <NavItem path="/appGallery" icon={faFiles} labelKey="appGallery" dataTestId="navigation-item-appGallery" />
        )}
        <MessagesNavItem
          path="/messages"
          icon={faMessageLines}
          labelKey="messages"
          dataTestId="navigation-item-messages"
        />
        {userType !== UserType.Admin && (
          <>
            <a
              className="ps-4 menu-item"
              role="button"
              tabIndex={0}
              onClick={onContactHandler}
              onKeyDown={onKeyDownContactHandler}
              data-test-id="navigation-item-contact"
            >
              <FontAwesomeIcon className="pe-3" icon={faEnvelope} />
              <span className="nav-link-label size-label">
                <FormattedMessage id="nav.items.contact" />
              </span>
            </a>
            {isShowSupportModal && <ClientSupportModal onClose={closeContactModalHandler} />}
          </>
        )}
        <NavItem path="/settings" icon={faGear} labelKey="settings" dataTestId="navigation-item-settings" />
        <a
          className="ps-4 menu-item"
          role="button"
          tabIndex={0}
          onClick={onClickLogoutHandler}
          onKeyDown={onKeyDownLogoutHandler}
          data-test-id="navigation-item-logout"
        >
          <FontAwesomeIcon className="pe-3" icon={faArrowRightFromBracket} />
          <span className="nav-link-label size-label">
            <FormattedMessage id="nav.items.logout" />
          </span>
        </a>
      </div>
    </div>
  );
};

export default Nav;
